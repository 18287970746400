import fp from 'lodash/fp';

const unflatObject = fp.flow([
  fp.toPairs,
  fp.reduce((result, [field, value]) => fp.set(field, value, result), {}),
]);

const yupValidation = (schema) => async (data) => {
  try {
    const trimmedData = trim(data);
    await schema.validate(trimmedData, { abortEarly: false });
    return {};
  } catch (error) {
    if (!error.inner || !Array.isArray(error.inner)) {
      console.error('Erro de validação sem propriedade inner válida:', error);
      return {};
    }

    const errors = error.inner.reduce(
      (formError, innerError) => ({
        ...formError,
        [innerError.path]: innerError.message,
      }),
      {},
    );
    return unflatObject(errors);
  }
};

const trim = (data) => {
  if (!data) return data;

  try {
    const stringifiedData = JSON.stringify(data);
    return JSON.parse(stringifiedData, (_key, value) => {
      return typeof value === 'string' ? value.trim() : value;
    });
  } catch (e) {
    console.error('Erro ao processar dados para trim:', e);
    return data;
  }
};

export default yupValidation;
